import type { FC } from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import ArrowLeftIcon from "@untitled-ui/icons-react/build/esm/ArrowLeft";
import { paths } from "src/paths";
import { useGtmVimeo } from "src/hooks/use-gtm-vimeo";
import { PricingPlan } from 'src/sections/pricing/pricing-plan';
import Grid from '@mui/material/Unstable_Grid2';
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

interface PriceSheetDialogProps {
  onClose?: () => void;
  open?: boolean;
  openCalendly: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PriceSheetDialog: FC<PriceSheetDialogProps> = (props) => {
  const { onClose, open = false, openCalendly } = props;

  useGtmVimeo('vimeo-pricesheet-product-demo'); // Custom hook to track Vimeo events

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={"lg"}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          backgroundColor: '#FAF9F8',
        }}
      >
        <Box
          sx={{
            p: 2,
          }}
        >
          <Button
            color="inherit"
            startIcon={
              <SvgIcon>
                <ArrowLeftIcon />
              </SvgIcon>
            }
            onClick={onClose}
          >
            Close
          </Button>
        </Box>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            mb: 2,
            mt: 0,
          }}
        >
          <Box
            sx={{
              m: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              maxWidth: "100%",
              alignItems: "center",
            }}
          >
            
            <Typography variant="h3" sx={{textAlign:'center', mb:5}}>
            Simple, clear pricing with no surprises.
          </Typography>
            <Grid
              container
              spacing={4}
            >
              <Grid
                xs={12}
                md={4}
              >
                <PricingPlan
                  step="Step 1"
                  stepName="Onboarding"
                  description="Choose branding for your client and agents. All onboarding fees are one-time per business."
                  features={[
                    'Create contracts',
                    'Chat support',
                    'Email alerts'
                  ]}
                  sx={{
                    height: '100%',
                    mx: 'auto'
                  }}
                />
              </Grid>
              <Grid
                xs={12}
                md={4}
              >
                <PricingPlan
                  step="Step 2"
                  stepName="Invitations"
                  description="Add your whole team, including agents and admins. Invite unlimited clients to the platform."
                  features={[
                    'Create contracts',
                    'Chat support',
                    'Email alerts'
                  ]}
                  sx={{
                    height: '100%',
                    mx: 'auto'
                  }}
                />
              </Grid>
              <Grid
                xs={12}
                md={4}
              >
                <PricingPlan
                  step="Step 3"
                  stepName="Purchases"
                  description="Set your prices and pay a flat rate when clients make purchases on your estate planning platform."
                  features={[
                    'Create contracts',
                    'Chat support',
                    'Email alerts'
                  ]}
                  sx={{
                    height: '100%',
                    mx: 'auto'
                  }}
                />
              </Grid>
            </Grid>
            <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
            <Button
                startIcon={<AutoAwesomeIcon />}
                variant="contained"
                href={paths.dashboard.upgrade}
              >
                Upgrade to Pro
              </Button>
              <Button
                variant="text"
                onClick={(e) => {
                  e.stopPropagation();
                  onClose?.(); // Safely calling onClose if it is defined
                  openCalendly(true);
                }}
              >
                Book a demo
              </Button>
              <Button
                variant="text"
                href={paths.dashboard.tutorials}
              >
                Tutorials
              </Button>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

PriceSheetDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
