import { useCallback } from "react";
import { useEstateAppApiContext } from "src/contexts/snug/estateAppApi-context";

export enum GenericPath {
  //v3
  GENERIC_DOCUMENT_CREATE_V3 = "/v3/{ud_id}/{entity}/{entity_id}/documents/",
  GENERIC_DOCUMENT_DELETE_V3 = "/v3/{ud_id}/{entity}/{entity_id}/documents/{document_id}/",
  GENERIC_DOCUMENT_DOWNLOAD_V3 = "/v3/{ud_id}/{entity}/{entity_id}/documents/{document_id}/download",
  GENERIC_SUBCATEGORY_ITEM_LIST_CREATE_V3 = "/v3/{ud_id}/{entity}/{entity_id}/{subcategory}/",
  GENERIC_SUBCATEGORY_ITEM_RETRIEVE_UPDATE_DELETE_V3 = "/v3/{ud_id}/{entity}/{entity_id}/{subcategory}/{subcategory_id}/",
  GENERIC_ENTITY_LIST_CREATE_V3 = "/v3/{ud_id}/{entity}/",
  GENERIC_ENTITY_RETRIEVE_UPDATE_DELETE_V3 = "/v3/{ud_id}/{entity}/{entity_id}/",

  USER_DATA_V3 = "/v3/user-data/",
  CLIENT_INVITE = "/v3/{ud_id}/{entity}/{entity_id}/{subcategory}/{subcategory_id}/invite",
  CLIENT_TRANSFER = "/v3/{ud_id}/{entity}/{entity_id}/{subcategory}/{subcategory_id}/transfer",
  LEAD_REQUEST_ACCESS = "/v3/{ud_id}/pro-group/{entity_id}/pro-people-roles/{subcategory_id}/request-access",
  PRO_GROUP_INVITE = "/v3/{ud_id}/pro-group/{entity_id}/invite",
  PRO_GROUP_IMAGE = "/v3/{ud_id}/pro-group/{entity_id}/image/",
  PRO_IMAGE = "/v3/{ud_id}/pro-group/{entity_id}/professional-group-role/{subcategory_id}/image/",
  LEADS = "/v3/{ud_id}/pro-group/{enity}/leads/",
  SECOND_DEGREE_INVITE = "/v3/{ud_id}/user-data/{entity_id}/invite",
  PRO_GROUP_REFERRAL = "/v3/{ud_id}/{entity}/{entity_id}/referral",
  PRO_GROUP_ROLE_UPGRADE = "/v3/{ud_id}/pro-group/{entity_id}/{subcategory}/{subcategory_id}/upgrade",
  PRO_GROUP_ROLE_DOWNGRADE = "/v3/{ud_id}/pro-group/{entity_id}/{subcategory}/{subcategory_id}/downgrade",
  CLIENT_EDIT_EMAIL = "/v3/{ud_id}/pro-group/{entity_id}/pro-people-roles/{subcategory_id}/email-edit",
  TRANSACTION_OVERVIEW = "/v3/{ud_id}/{entity}/{entity_id}/{subcategory}",
  TRANSACTION_AGENT_OVERVIEW = "/v3/{ud_id}/{entity}/{entity_id}/{subcategory}/{subcategory_id}",
  WHITE_LABEL_CONFIG_CREATE = "/v3/{ud_id}/{entity}/{entity_id}/white-label-config/",
  CLIENT_COUNT = "/v3/{ud_id}/pro-group/{entity_id}/pro-people-roles/count",
}

export type PaginationResponse<T> = {
  count: number;
  next: string | null;
  previous: string | null;
  data: T[];
};

type V3UrlParams = {
  ud_id: string;
  entity: string;
  subcategory?: string;
  subcategory_2?: string;
  legacy_documents_subcategory?: string;
  entity_id?: string;
  document_id?: string;
  subcategory_id?: string;
  subcategory_id_2?: string;
};

const replacePathPlaceholders = (pathTemplate: string, params: V3UrlParams) => {
  return pathTemplate
    .replace("{ud_id}", params.ud_id)
    .replace("{entity}", params.entity)
    .replace("{entity_id}", params.entity_id || "")
    .replace("{document_id}", params.document_id || "")
    .replace("{subcategory_id}", params.subcategory_id || "")
    .replace("{subcategory}", params.subcategory || "")
    .replace(
      "{legacy_documents_subcategory}",
      params.legacy_documents_subcategory || ""
    )
    .replace("{subcategory_2}", params.subcategory_2 || "")
    .replace("{subcategory_id_2}", params.subcategory_id_2 || "");
};

export const useV3GenericGetData = <T,>(genericPath: string) => {
  const { estateAppApiInstance } = useEstateAppApiContext();

  const genericGetData = useCallback(
    async (
      params: V3UrlParams,
      queryParams: Object = {},
      signal?: AbortSignal
    ): Promise<T> => {
      const path = replacePathPlaceholders(genericPath, params);

      const response = await estateAppApiInstance.get(path, {
        params: queryParams,
        signal, // Pass the AbortSignal to the API request
      });

      return response.data.data as T;
    },
    [estateAppApiInstance, genericPath]
  );

  return genericGetData;
};

export const useV3GenericGetDataWithPagination = <T,>(genericPath: string) => {
  const { estateAppApiInstance } = useEstateAppApiContext();

  const genericGetData = useCallback(
    async (
      params: V3UrlParams,
      queryParams: Object = {},
      signal?: AbortSignal
    ): Promise<PaginationResponse<T>> => {
      const path = replacePathPlaceholders(genericPath, params);

      const response = await estateAppApiInstance.get(path, {
        params: queryParams,
        signal, // Pass the AbortSignal to the API request
      });

      return response.data as PaginationResponse<T>;
    },
    [estateAppApiInstance, genericPath]
  );

  return genericGetData;
};

export const useV3Update = <T,>(genericPath: string) => {
  const { estateAppApiInstance } = useEstateAppApiContext();

  const update = useCallback(
    async (
      params: V3UrlParams,
      data: any,
      isPartialUpdate: boolean = false
    ): Promise<T> => {
      const path = replacePathPlaceholders(genericPath, params);
      let response;
      if (isPartialUpdate) {
        // Use PATCH for partial updates and PUT for full updates, depending on your API design
        response = await estateAppApiInstance.patch(path, data);
      } else {
        response = await estateAppApiInstance.put(path, data);
      }
      return response.data.data as T;
    },
    [estateAppApiInstance, genericPath]
  );

  return update;
};

export const useV3Create = <T,>(genericPath: string) => {
  const { estateAppApiInstance } = useEstateAppApiContext();

  const create = useCallback(
    async (params: V3UrlParams, data: any): Promise<T> => {
      const path = replacePathPlaceholders(genericPath, params);
      const response = await estateAppApiInstance.post(path, data);
      return response.data.data as T;
    },
    [estateAppApiInstance, genericPath]
  );

  return create;
};

export const useV3DeleteById = (genericPath: string): any => {
  const { estateAppApiInstance } = useEstateAppApiContext();

  const deleteById = useCallback(
    async (params: V3UrlParams, queryParams: Object = {}): Promise<void> => {
      const path = replacePathPlaceholders(
        replacePathPlaceholders(genericPath, params),
        params
      );
      const res = await estateAppApiInstance.delete(path, {
        params: queryParams,
      });
      return res.data;
    },
    [estateAppApiInstance, genericPath]
  );
  return deleteById;
};

/////////////////////////////////////////////////////

/// START V3 DOCUMENTS

export const useV3UploadDocument = <T,>(genericPath: string) => {
  const { estateAppApiInstance } = useEstateAppApiContext();

  const uploadDocument = useCallback(
    async (params: V3UrlParams, file: File): Promise<T> => {
      const formData = new FormData();
      formData.append("file", file);
      const path = replacePathPlaceholders(genericPath, params);
      const res = await estateAppApiInstance.post(path, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data.data as T;
    },
    [estateAppApiInstance, genericPath]
  );

  return uploadDocument;
};

export const useV3UploadMultipleFiles = <T,>(genericPath: string) => {
  const { estateAppApiInstance } = useEstateAppApiContext();

  const uploadDocument = useCallback(
    async (params: V3UrlParams, files: File[]): Promise<T> => {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("files", file);
      });
      const path = replacePathPlaceholders(genericPath, params);
      const res = await estateAppApiInstance.post(path, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data.data as T;
    },
    [estateAppApiInstance, genericPath]
  );
  return uploadDocument;
};

export const useV3DocumentDelete = (genericPath: string) => {
  const { estateAppApiInstance } = useEstateAppApiContext();

  const deleteDocument = useCallback(
    async (params: V3UrlParams): Promise<void> => {
      const path = replacePathPlaceholders(genericPath, params);
      await estateAppApiInstance.delete(path);
    },
    [estateAppApiInstance, genericPath]
  );

  return deleteDocument;
};

export const useV3DocumentDownload = (genericPath: string) => {
  const { estateAppApiInstance } = useEstateAppApiContext();

  const downloadDocument = useCallback(
    async (params: V3UrlParams): Promise<void> => {
      const path = replacePathPlaceholders(genericPath, params);
      const res = await estateAppApiInstance.get(path);
      return res.data.data;
    },
    [estateAppApiInstance, genericPath]
  );
  return downloadDocument;
};

// END V3 DOCUMENTS

export const useUploadProfessionalGroupImage = <T,>(path: string) => {
  const { estateAppApiInstance } = useEstateAppApiContext();

  const uploadImage = useCallback(
    async (
      ud_id: string,
      entity_id: string,
      file: File,
      sourceFile: File
    ): Promise<T> => {
      if (!file) return Promise.reject("No file provided");
      if (!sourceFile) return Promise.reject("No sourceFile provided");

      const formData = new FormData();
      formData.append("file", file);
      formData.append("source_file", sourceFile);

      const updatedPath = path
        .replace("{ud_id}", ud_id)
        .replace("{entity_id}", entity_id);

      const response = await estateAppApiInstance.put(updatedPath, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data.data as T;
    },
    [estateAppApiInstance, path]
  );

  return uploadImage;
};

export const useUploadProfessionalGroupRoleImage = <T,>(path: string) => {
  const { estateAppApiInstance } = useEstateAppApiContext();

  const uploadImage = useCallback(
    async (
      ud_id: string,
      entity_id: string,
      subcategory_id: string,
      file: File,
      sourceFile: File
    ): Promise<T> => {
      if (!file) return Promise.reject("No file provided");
      if (!sourceFile) return Promise.reject("No sourceFile provided");

      const formData = new FormData();
      formData.append("file", file);
      formData.append("source_file", sourceFile);

      const updatedPath = path
        .replace("{ud_id}", ud_id)
        .replace("{entity_id}", entity_id)
        .replace("{subcategory_id}", subcategory_id);

      const response = await estateAppApiInstance.put(updatedPath, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data.data as T;
    },
    [estateAppApiInstance, path]
  );

  return uploadImage;
};

export const useUploadCRMCSV = () => {
  const { estateAppApiInstance } = useEstateAppApiContext();

  const uploadGeneralDocument = useCallback(
    async (file: File) => {
      if (!file) return Promise.reject("No file provided");

      const formData = new FormData();
      formData.append("file", file);

      return await estateAppApiInstance.post(
        GenericPath.CLIENT_INVITE,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
    [estateAppApiInstance]
  );

  return uploadGeneralDocument;
};
