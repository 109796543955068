import { useEffect, useRef } from "react";
import { gtm } from "src/libs/gtm";

export const useGtmVimeo = (iframeId: string): void => {
  const videoDurationRef = useRef<number | null>(null);

  useEffect(() => {
    const iframe = document.getElementById(iframeId) as HTMLIFrameElement;
    if (!iframe) {
      console.warn(`No iframe found with ID: ${iframeId}`);
      return;
    }

    const handleVimeoMessage = (event: MessageEvent) => {
      // Validate the origin of the message
      if (!event.origin.includes("vimeo.com")) return;

      let data;
      try {
        data = JSON.parse(event.data); // Parse the message data
      } catch {
        return; // Exit if data is not valid JSON
      }

      // Check for duration response (There are two common message formats from Vimeo:
      // 1) event: 'ready' and event: 'play', etc.
      // 2) responses to method calls (like getDuration) that do not have an "event" key, just "value".
      //
      // The response from getDuration might look like: { "method": "getDuration", "value": 123.456 }
      if (data.method === "getDuration" && typeof data.value === "number") {
        videoDurationRef.current = data.value;
        return;
      }

      if (data.event === "ready") {
        // Subscribe to events
        iframe.contentWindow?.postMessage(
          JSON.stringify({ method: "addEventListener", value: "play" }),
          "*"
        );
        iframe.contentWindow?.postMessage(
          JSON.stringify({ method: "addEventListener", value: "pause" }),
          "*"
        );
        iframe.contentWindow?.postMessage(
          JSON.stringify({ method: "addEventListener", value: "ended" }),
          "*"
        );

        // Request the total video duration
        iframe.contentWindow?.postMessage(
          JSON.stringify({ method: "getDuration" }),
          "*"
        );
      } else if (data.event === "play") {
        // For play events, you might still want to send something. We don't have a percentage yet,
        // but you could send the current play time or just note that it started.
        gtm.push({ event: "vimeo_play", videoId: iframeId });
      } else if (data.event === "pause" || data.event === "ended") {
        // Calculate the viewed percentage if we have a duration
        if (videoDurationRef.current && videoDurationRef.current > 0) {
          const viewedPercentage = (data.data.seconds / videoDurationRef.current) * 100;

          if (data.event === "pause") {
            gtm.push({ 
              event: "vimeo_pause", 
              videoId: iframeId, 
              vimeoViewedPercentage: viewedPercentage 
            });
          } else {
            gtm.push({ 
              event: "vimeo_end", 
              videoId: iframeId, 
              vimeoViewedPercentage: viewedPercentage 
            });
          }
        } else {
          // If we didn't get the duration for some reason, fallback to seconds
          if (data.event === "pause") {
            gtm.push({ event: "vimeo_pause", videoId: iframeId, vimeoTime: data.data.seconds });
          } else {
            gtm.push({ event: "vimeo_end", videoId: iframeId, vimeoTime: data.data.seconds });
          }
        }
      }
    };

    window.addEventListener("message", handleVimeoMessage);

    return () => {
      window.removeEventListener("message", handleVimeoMessage);
    };
  }, [iframeId]);

};
